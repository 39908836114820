import React, { useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const modules = {
    toolbar: [
        [{ header: [2, false] }],
        ["bold", "italic", "underline", "strike"],
        [{ list: "ordered" }, { list: "bullet" }],
        ["link", "image"],
        ["clean"],
    ],
};

const CareerHubForm = ({ employer, handleSubmit }) => {
    const [formData, setFormData] = useState({
        careerHubH1: employer?.careerHubH1 || "",
        careerHubDescription: employer?.careerHubDescription || "",
        careerHubSideBar: employer?.careerHubSideBar || "",
        careerHubMetaTitle: employer?.careerHubMetaTitle || "",
        careerHubMetaDescription: employer?.careerHubMetaDescription || "",
        careerHubBanner: employer?.careerHubBanner || null,
    });

    const onUploadAvatar = async (event) => {
        const file = event.target.files[0];
        if (!file) return;

        console.log("Uploading file:", file.name);

        try {
            // Step 1: Request a pre-signed URL
            const response = await fetch(
                "https://api2.sciencejobs.com.au/api/generate-upload-url",
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "X-Dev-Secret": process.env.REACT_APP_LOCAL_DEV_SECRET,
                    },
                    body: JSON.stringify({ fileName: file.name, directory: "university-logo" }),
                }
            );

            const { presignedUploadUrl } = await response.json();
            console.log("Pre-signed URL received:", presignedUploadUrl);

            // Step 2: Upload file to AWS S3 using the pre-signed URL
            await fetch(presignedUploadUrl, {
                method: "PUT",
                body: file,
            });

            console.log("File uploaded successfully:", file.name);

            // Step 3: Update state with the new image filename
            setFormData((prevState) => ({
                ...prevState,
                careerHubBanner: file.name,
            }));
        } catch (error) {
            console.error("Error uploading file:", error);
        }
    };

    const handleChange = (e) => {
        const { name, value, type, files } = e.target;
        setFormData({
            ...formData,
            [name]: type === "file" ? files[0] : value,
        });
    };

    const handleQuillChange = (field, value) => {
        setFormData((prevState) => ({
            ...prevState,
            [field]: value,
        }));
    };

    const onSubmit = (e) => {
        e.preventDefault();
        console.log("Submitting form data:", formData);
        handleSubmit(formData);
    };

    return (
        <form onSubmit={onSubmit} className="w-1/2 p-4">

            {/* Image Preview */}
            {formData.careerHubBanner && (
                <div className="mb-4">
                    <label className="block text-gray-700 font-bold mb-2">Profile Banner</label>
                    <img
                        src={`https://academicjobs.s3.amazonaws.com/img/university-logo/${formData.careerHubBanner}`}
                        alt="Career Hub Banner"
                        className="w-full h-auto object-contain rounded-md bg-white"
                    />
                </div>
            )}

            {/* File Upload */}
            <div className="mb-4">
                <input
                    type="file"
                    name="careerHubBanner"
                    onChange={onUploadAvatar}
                    className="w-full p-2 border rounded"
                />
            </div>

            <div className="mb-4">
                <label className="block text-gray-700 font-bold mb-2">Meta Title</label>
                <input
                    type="text"
                    name="careerHubMetaTitle"
                    value={formData.careerHubMetaTitle}
                    onChange={handleChange}
                    className="w-full p-2 border rounded"
                />
            </div>

            <div className="mb-4">
                <label className="block text-gray-700 font-bold mb-2">Meta Description</label>
                <textarea
                    name="careerHubMetaDescription"
                    value={formData.careerHubMetaDescription}
                    onChange={handleChange}
                    className="w-full p-2 border rounded"
                    rows="3"
                />
            </div>

            <div className="mb-4">
                <label className="block text-gray-700 font-bold mb-2">Profile H1</label>
                <input
                    type="text"
                    name="careerHubH1"
                    value={formData.careerHubH1}
                    onChange={handleChange}
                    className="w-full p-2 border rounded"
                    required
                />
            </div>

            {/* ReactQuill for Description */}
            <div className="mb-4">
                <label className="block text-gray-700 font-bold mb-2">Profile Banner Content</label>
                <ReactQuill
                    value={formData.careerHubDescription}
                    onChange={(value) => handleQuillChange("careerHubDescription", value)}
                    modules={modules}
                    theme="snow"
                    className="bg-white"
                    style={{ minHeight: '20px' }}

                />
            </div>

            {/* ReactQuill for Side Bar Content */}
            <div className="mb-4">
                <label className="block text-gray-700 font-bold mb-2">Side Bar Content</label>
                <ReactQuill
                    value={formData.careerHubSideBar}
                    onChange={(value) => handleQuillChange("careerHubSideBar", value)}
                    modules={modules}
                    theme="snow"
                    className="bg-white"
                    style={{ minHeight: '20px' }}

                />
            </div>


            <button
                type="submit"
                className="w-full bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 transition duration-300"
            >
                Submit
            </button>
        </form>
    );
};

export default CareerHubForm;
