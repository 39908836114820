import React, { useState, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
    useGetSalesNotesQuery,
    useAddSalesNotesMutation,
} from "../store/apiSlice"
import AddSalesNotes from './AddSalesNotes'
import SalesNotesRowEdit from './SalesNotesRowEdit'
const MostRecentQA = ({ id }) => {
    console.log('MostRecentQA')
    
    useEffect(() => {
        console.log('MostRecentQA is running times')
    }, [])
    
    const dispatch = useDispatch()
    const {
        data,
        isLoading,
        isSuccess,
        isError,
        error,
    } = useGetSalesNotesQuery({ id })
    let content
    if (isLoading) {
        content = <p>Loading...</p>
    }
    if (isError) {
        content = <p>No sales notes found...</p>
    }
    if (isSuccess && data?.length > 0) {
        console.log("data", data)
        content = data.map((note, key) =>
        (<SalesNotesRowEdit note={note} key={ key} />
           
        ))
    }
    return <div className='flex flex-col gap-3 w-full overflow-y-auto p-4 h-full'>
        <h2 className='font-bold underline pt-2'>Sales Notes</h2>
        <AddSalesNotes id={id } />
        {content}
    </div>
}
export default MostRecentQA
