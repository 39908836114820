// cSpell:disable
export const productData = {
    AUD: [
      {
        id: 'prod_PQqDcoljVuzlC9', // Stripe ID
        xeroId: 'BJA-1',
        name: 'Single Job Post',
        slug: 'save-pay-post/1',
        currency: 'AUD',
        description: 'Single Job Post',
        price: 315,
        currencySymbol: '$',
        credits: 2,
        stripeLink: 'https://buy.stripe.com/dR6eWA6PuaaA7VC6ov',
      },
      {
        id: 'prod_QJYO5siOzxegVU', // Stripe ID
        xeroId: 'BJA-3',
        name: '3 Job Pack',
        slug: '3-job-pack-aud',
        currency: 'AUD',
        description: '3 Job Pack',
        price: 888,
        currencySymbol: '$',
        credits: 3,
        stripeLink: 'https://buy.stripe.com/dR629O4HmgyY1xe6oM',
      },
      {
        id: 'prod_QJYANJaIsM8Awo', // Stripe ID
        name: 'Unlimited',
        slug: 'unlimited-job-posts-subscription-aud',
        currency: 'AUD',
        description: 'Unlimited Job Posts Subscription',
        price: 888,
        currencySymbol: '$',
        credits: -1,
        stripeLink: 'https://buy.stripe.com/fZeaGk1vaaaAdfWeVh',
      },
    ],
    USD: [
      {
        id: 'prod_QMUFGgxEotLfrh', // Stripe ID
        xeroId: 'BJA-1',
        name: 'Single Job Post',
        slug: 'single-job-post-usd',
        currency: 'USD',
        description: 'Single Job Post',
        price: 315,
        currencySymbol: '$',
        credits: 2,
        stripeLink: 'https://buy.stripe.com/5kA29O0r6aaAgs85kP',
      },
      {
        id: 'prod_QMTYh7tPUjL36m', // Stripe ID
        xeroId: 'BJA-3',
        name: '3 Job Pack',
        slug: '3-job-pack-usd',
        currency: 'USD',
        description: '3 Job Pack',
        price: 888,
        currencySymbol: '$',
        credits: 3,
        stripeLink: 'https://buy.stripe.com/9AQaGkddSbeEdfWdRm',
      },
      {
        id: 'prod_QJYANJaIsM8Awo', // Stripe ID
        name: 'Unlimited',
        slug: 'unlimited-job-posts-subscription-usd',
        currency: 'USD',
        description: 'Unlimited Job Posts Subscription',
        price: 888,
        currencySymbol: '$',
        credits: -1,
        stripeLink: 'https://buy.stripe.com/4gwdSw1va6Yo7VC3cJ',
      },
    ],
    NZD: [
      {
        id: 'prod_Q4UhHuD9LEI2yP', // Stripe ID
        xeroId: 'BJA-1',
        name: 'Single Job Post',
        slug: 'single-job-post-nzd',
        currency: 'NZD',
        description: 'Single Job Post',
        price: 315,
        currencySymbol: '$',
        credits: 2,
        stripeLink: 'https://buy.stripe.com/fZebKo1va2I82BiaF4',
      },
      {
        id: 'prod_QMUtpv6vUiRi8F', // Stripe ID
        xeroId: 'BJA-3',
        name: '3 Job Pack',
        slug: '3-job-pack-nzd',
        currency: 'NZD',
        description: '3 Job Pack',
        price: 888,
        currencySymbol: '$',
        credits: 3,
        stripeLink: 'https://buy.stripe.com/cN229O2ze3Mcek000z',
      },
      {
        id: 'prod_QMUoeYTEfkg9lb', // Stripe ID
        name: 'Unlimited',
        slug: 'unlimited-job-posts-subscription-nzd',
        currency: 'NZD',
        description: 'Unlimited Job Posts Subscription',
        price: 888,
        currencySymbol: '$',
        credits: -1,
        stripeLink: 'https://buy.stripe.com/eVa5m05Lq5Uk3FmeVs',
      },
    ],
  };
  