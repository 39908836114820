import React, { useState } from 'react';
import { useGetAllUsersQuery, useRegisterUserMutation } from './auth/authApiSlice';
import { useAddOtherContactsMutation } from '../store/apiSlice';

const UserList = ({ id, userType, employer_name, employer_details }) => {
  const { data, error, isLoading } = useGetAllUsersQuery(id);
  const [registerUser] = useRegisterUserMutation();
  const [addOtherContacts] = useAddOtherContactsMutation();

  const [formData, setFormData] = useState({
    userType,
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    orgnization_name: employer_name,
    country: employer_details?.country,
    state: employer_details?.state,
    employer_id: id,
    title: '',
    phone: '',
    hrYN: false,
    recruiterYN: false,
    departmentLeadYN: false,
    contactType: 'MAIN',
  });

  const [message, setMessage] = useState('');
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Register user
      const userResponse = await registerUser({
        userType: formData.userType,
        firstName: formData.firstName,
        lastName: formData.lastName,
        email: formData.email,
        password: formData.password,
        orgnization_name: formData.orgnization_name,
        country: formData.country,
        state: formData.state,
        employer_id: formData.employer_id,
      }).unwrap();

      if (userResponse) {
        // Add to other contacts
        await addOtherContacts({
          firstName: formData.firstName,
          lastName: formData.lastName,
          email: formData.email,
          phone: formData.phone,
          title: formData.title,
          employer_id: formData.employer_id,
          hrYN: formData.hrYN,
          recruiterYN: formData.recruiterYN,
          departmentLeadYN: formData.departmentLeadYN,
          contactType: formData.contactType,
        }).unwrap();
      }
      setMessage('User and contact successfully added!');
      setFormData({
        userType,
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        orgnization_name: employer_name,
        country: employer_details?.country,
        state: employer_details?.state,
        employer_id: id,
        title: '',
        phone: '',
        hrYN: false,
        recruiterYN: false,
        departmentLeadYN: false,
        contactType: 'MAIN',
      });
    } catch (error) {
      setMessage(`Error: ${error.message || 'Failed to register user'}`);
    }
  };

  if (isLoading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  return (
    <div className="p-4">
      <div className="bg-white shadow-md rounded-lg p-6 max-h-[600px] overflow-y-auto custom-scrollbar">
        <h2 className="text-xl font-semibold text-center mb-4">
          {userType === 'employee' ? 'Employees' : 'Users'}
        </h2>
        <ul className="list-none">
          {userType === 'employee' &&
            data?.employees?.map((employee) => (
              <li key={employee.id} className="py-2 border-b border-gray-200">
                <p className="text-gray-700 font-medium">
                  {employee.firstName} {employee.lastName}
                </p>
                <p className="text-gray-500 text-sm">{employee.email}</p>
              </li>
            ))}
          {userType === 'employer' &&
            data?.employers?.map((employer) => (
              <li key={employer.id} className="py-2 border-b border-gray-200">
                <p className="text-gray-700 font-medium">
                  {employer.firstName} {employer.lastName}
                </p>
                <p className="text-gray-500 text-sm">{employer.email}</p>
                {employer.Employer ? (
                  <p className="text-gray-600 text-sm mt-2">
                    <strong>Company: </strong>
                    {employer.Employer.company_name}
                  </p>
                ) : (
                  <p className="text-gray-600 text-sm mt-2">
                    <strong>Company: </strong> Not associated with a company
                  </p>
                )}
              </li>
            ))}
        </ul>
      </div>
      <div className="bg-white shadow-md rounded-lg p-6 w-full mx-auto mt-8">
        <h2 className="text-xl font-semibold text-center mb-4">Register New User</h2>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div className="flex space-x-4">
            <input
              type="text"
              name="firstName"
              placeholder="First Name"
              value={formData.firstName}
              onChange={handleChange}
              className="w-full p-2 border border-gray-300 rounded-md"
              required
            />
            <input
              type="text"
              name="lastName"
              placeholder="Last Name"
              value={formData.lastName}
              onChange={handleChange}
              className="w-full p-2 border border-gray-300 rounded-md"
              required
            />
          </div>

          <div className="flex space-x-4">
            <input
              type="email"
              name="email"
              placeholder="Email"
              value={formData.email}
              onChange={handleChange}
              className="w-full p-2 border border-gray-300 rounded-md"
              required
            />
            <input
              type="text"
              name="password"
              placeholder="Password"
              value={formData.password}
              onChange={handleChange}
              className="w-full p-2 border border-gray-300 rounded-md"
              required
            />
          </div>

          {userType === 'employer' && (
            <div className="flex space-x-4">
              <input
                type="text"
                name="employer_id"
                placeholder="Employer ID"
                value={formData.employer_id}
                className="w-full p-2 border border-gray-300 rounded-md"
              />
              <input
                type="text"
                name="orgnization_name"
                placeholder="Organization Name"
                value={formData.orgnization_name}
                onChange={handleChange}
                className="w-full p-2 border border-gray-300 rounded-md"
                readOnly
              />
            </div>)}

          <div className="flex space-x-4">
            <input
              type="text"
              name="country"
              placeholder="Country"
              value={formData.country}
              onChange={handleChange}
              className="w-full p-2 border border-gray-300 rounded-md"
              required
            />
            <input
              type="text"
              name="state"
              placeholder="State"
              value={formData.state}
              onChange={handleChange}
              className="w-full p-2 border border-gray-300 rounded-md"
              required
            />
          </div>
          {/* <div className="flex space-x-4">
            <input
              type="text"
              name="postCode"
              placeholder="Post Code"
              value={formData.postCode}
              onChange={handleChange}
              className="w-full p-2 border border-gray-300 rounded-md"
              required
            />
            <input
              type="text"
              name="phone"
              placeholder="Phone"
              value={formData.phone}
              onChange={handleChange}
              className="w-full p-2 border border-gray-300 rounded-md"
            />
          </div> */}
          <div className="flex space-x-4">
            <input type="text" name="title" className="w-full p-2 border border-gray-300 rounded-md" placeholder="Title" value={formData.title} onChange={handleChange} />
            <input type="text" name="phone" className="w-full p-2 border border-gray-300 rounded-md" placeholder="Phone" value={formData.phone} onChange={handleChange} />
          </div>
          <div className="flex space-x-4">
            <label><input type="checkbox" name="hrYN" checked={formData.hrYN} onChange={handleChange} /> HR</label>
            <label><input type="checkbox" name="recruiterYN" checked={formData.recruiterYN} onChange={handleChange} /> Recruiter</label>
            <label><input type="checkbox" name="departmentLeadYN" checked={formData.departmentLeadYN} onChange={handleChange} /> Department Lead</label>
          </div>

          <div className="space-x-4">
            <label className='font-bold'>Contact Type:</label>
            <label><input type="radio" name="contactType" value="MAIN" checked={formData.contactType === 'MAIN'} onChange={handleChange} /> Main</label>
            <label><input type="radio" name="contactType" value="SECONDARY" checked={formData.contactType === 'SECONDARY'} onChange={handleChange} /> Secondary</label>
            <label><input type="radio" name="contactType" value="OTHER" checked={formData.contactType === 'OTHER'} onChange={handleChange} /> Other</label>
          </div>

          <button type="submit" className="mx-auto flex bg-blue-500 text-white p-2 rounded-md">Register User & Add Contact</button>
        </form>
        {message && <p className={message.includes('Error') ? 'text-red-500' : 'text-green-500'}>{message}</p>}
      </div>
    </div>

  );
};

export default UserList;
