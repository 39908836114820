import React, { useEffect, useMemo, useRef, useState } from "react";
import Autocomplete from "react-google-autocomplete";
import { useForm } from "react-hook-form";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from 'react-router-dom';
import * as yup from "yup";
import {
  useCreateEmployerMutation,
  useGetCampusesMutation,
  useGetEmployerSuggestionsQuery,
  useUpdateEmployerMutation
} from "../store/apiSlice";
import InputBlock4 from "./InputBlock4";
import OtherContacts from "./OtherContacts";
import SalesNotes from "./SalesNotes";
//import { setemployer} from "./postSlice"
import ImageResize from 'quill-image-resize-module-react';
import CreateInvoice from "../components/CreateInvoice";
import { setEmployer } from "../store/postsSlice";
import AddEditUsers from "./AddEditUsers";
import SearchResults2 from "./SearchResults2";
import CareerHubProfile from "./../components/CareerHubProfile";

const users = [
  {
    id: 76,
    firstName: "Mary Rose Joana",
    portrait: "mary_rose_joana_pecson.jpg",
  },
  {
    id: 77,
    firstName: "Joan",
    portrait: "joan_santos.jpg",
  },
  {
    id: 182,
    firstName: "Clarissa",
    portrait: "ClarissaPacetes.png"
  },
  {
    id: 79,
    firstName: "Fausto",
    portrait: "fausto_san_juan.jpg",
  },
  {
    id: 81,
    firstName: "Jhon Michael",
    portrait: "jhon_michael_cruz.jpg",
  },
  {
    id: 82,
    firstName: "Bianca",
    portrait: "Bianca_Cacho_ID Picture copy.jpg",
  },
  {
    id: 83,
    firstName: "Laira Andrea",
    portrait: "laira_andrea_austria.jpg",
  },
  {
    id: 190,
    firstName: "Maria Luisa Violeta",
    portrait: "cai_portrait.jpg"
  },
  {
    id: 188,
    firstName: "Pauline",
    portrait: "pauline_aura.jpg"
  },
  {
    id: 86,
    firstName: "Don",
    portrait: "don_fernandez.jpg",
  },
  {
    id: 4,
    firstName: "Jarrod Kanizay",
    portrait: "jarrod_kanizay.webp",
  }
];
const validationSchema = yup
  .object({
    // username: yup.string().required('Missing username'),
    // email: yup.string().required('Missing email').email('Invalid email format'),
    //password: yup.string().required('Missing password'),
  })
  .required();
const EmployerAddEdit = ({ employer }) => {
  window.Quill = Quill
  Quill.register('modules/imageResize', ImageResize);
  const modules = {
    toolbar: [
      [{ header: '2' }, { font: [] }],
      [{ size: [] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [
        { list: 'ordered' },
        { list: 'bullet' },
        { indent: '-1' },
        { indent: '+1' }
      ],
      ['link', 'image', 'video'],
      ['clean']
    ],
    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: false
    },
    imageResize: {
      parchment: Quill.import('parchment'),
      modules: ['Resize', 'DisplaySize']
    }
  };
  const formats = ['image'];

  const location = useLocation();
  console.log("=====employer=============", employer);
  const [activeTab, setActiveTab] = useState(location.state?.activeTab || "profile");
  const googleAddressRef = useRef();
  const isAddMode = !employer;
  console.log("isAddMode", isAddMode);
  const dispatch = useDispatch();
  const userInfo = useSelector((state) => state.auth.userInfo);
  const regions = [
    "Australia",
    "Asia",
    "Africa",
    "New Zealand",
    "Canada",
    "Europe",
    "India",
    "Global",
    "United States",
    "United Kingdom",
  ];
  console.log("EditEmployerProfile");
  const [logo, setLogo] = useState("");
  const onUploadAvatar = async (event) => {
    // Call API to BE to generate a pre-signed url to upload file object
    console.log("event.target.files[0].name", event.target.files[0].name);
    const response = await fetch(
      "https://api2.sciencejobs.com.au/api/generate-upload-url",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json", // Specify the content type as JSON
        },
        "X-Dev-Secret": process.env.REACT_APP_LOCAL_DEV_SECRET,

        body: JSON.stringify({ fileName: event.target.files[0].name, directory: 'university-logo' }),
      }
    ); //'https://api2.sciencejobs.com.au/api/generate-upload-url'
    const { presignedUploadUrl } = await response.json();
    console.log(presignedUploadUrl);
    // Use the pre-signed upload url to upload the image
    const imageResponse = await fetch(presignedUploadUrl, {
      method: "PUT", // or 'POST' depending on your server requirements
      body: event.target.files[0],
    });
    setLogo(event.target.files[0].name);
    setValue("logo", event.target.files[0].name);
    console.log(event.target.files[0].name);
    //console.log(imageResponse)
    //console.log(imageResponse.json())
    //const { url } = await imageResponse.json();
    //console.log(url)
    // Trigger onChange callback of react-hook-form to set uploaded image url string to form
    //onChange(event.target.files[0].name);
  };
  const employerCurrent = useSelector((state) => state.posts.employer);
  let defaultValues;
  const {
    register,
    reset,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
    setError,
  } = useForm({
    defaultValues: useMemo(() => {
      return defaultValues;
    }, [defaultValues]),
  });
  const { onChange, ...avatarField } = register("avatar");
  const [employerStatus, setEmployerStatus] = useState("Post Employer");
  const editorContent = watch("description");
  const editorContent1 = watch("employer_name");
  const [query, setQuery] = useState("");
  const [showSuggestions, setShowSuggestions] = useState(true);
  const inputRef = useRef();
  const submitRef = useRef();
  const countryRef = useRef("");
  const { data: suggestions = [] } = useGetEmployerSuggestionsQuery(
    { query, country: countryRef.current || "" },
    {}
  );
  const [
    updateEmployer,
    {
      isLoading: isLoadingUpdateEmployer,
      isSuccess: isSuccessUpdateEmployer,
      isError: isErrorUpdateEmployer,
      error: errorUpdateEmployer,
    },
  ] = useUpdateEmployerMutation();
  const [
    createEmployer,
    {
      data,
      isLoading: isLoadingCreateEmployer,
      isSuccess: isSuccessCreateEmployer,
      isError: isErrorCreateEmployer,
      error: errorCreateEmployer,
    },
  ] = useCreateEmployerMutation();
  const [newList, setNewList] = useState([]);
  const [newListName, setNewListName] = useState(null);
  const [newList1, setNewList1] = useState([]);
  const [newList1Name, setNewList1Name] = useState(null);
  const userid = useSelector((state) => state.auth.userid);
  const country = useSelector((state) => state.auth.country);
  const company_name = useSelector((state) => state.auth.company_name);
  const navigate = useNavigate();
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (inputRef.current && !inputRef.current.contains(event.target)) {
        setShowSuggestions(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);
  useEffect(() => {
    if (employer) {
      setLogo(employer?.logo);
      reset(employer);
      //setValue("location1", employer.location);
      //console.log(employer.location)
      //googleAddressRef.current.innerText ="hahahahaha" //employer.location
    } else {
      reset({ ranking: "3" });
      setValue("clientType", "HeadlineOnly");
    }
    setValue("postedBy", userInfo.id);
  }, [employer]);
  useEffect(() => {
    register("description", { required: false });
    register("company_name1", { required: false });
    register("country", { required: false });
  }, [register]);
  const onEditorStateChange = (editorState) => {
    setValue("description", editorState);
  };
  const onEditorStateChange1 = (
    company_name,
    employer_id,
    Region,
    country,
    logo
  ) => {
    setValue("company_name", company_name);
    setValue("employer_id", employer_id || 0);
    setValue("Region", Region);
    setValue("country", country);
    setValue("logo", logo);
    dispatch(setEmployer({ company_name, employer_id, logo }));
    if (employer_id) getCampuses({ id: employer_id });
  };
  function findValueForKey(array, keyToFind) {
    for (const obj of array) {
      const keys = Object.keys(obj);
      if (keys.includes(keyToFind)) {
        return obj[keyToFind];
      }
    }
    return null;
  }
  const [getCampuses, { data: dataCampuses }] = useGetCampusesMutation();
  let content, content1;
  const recordRef = useRef({ employer_id: userid });
  // const handleChange1 = (newValue) => {
  //   console.log(newValue, findValueForKey(employer_category, newValue))
  //   setNewList(findValueForKey(employer_category, newValue));
  //   setNewListName(newValue);
  //   setNewList1([]);
  //   setNewList1Name(null);
  // };
  // const handleChange2 = (newValue, flag) => {
  //   console.log(newValue, flag, findValueForKey(employer_category, newValue))
  //   recordRef.current = { ...recordRef.current, [flag]: newValue };
  //   console.log(recordRef.current);
  //   setNewList1(findValueForKey(employer_category, newValue));
  //   setNewList1Name(newValue);
  //   setValue(categoryCovert[newListName], newValue);
  // };
  const handleChange3 = (newValue, flag) => {
    recordRef.current = { ...recordRef.current, [flag]: newValue };
    console.log(recordRef.current);
  };
  const onSubmit = async (data) => {
    console.log("data", data);
    return isAddMode
      ? createEmployer1(data)
      : updateEmployer1({ id: employer?.id, ...data });
  };
  async function createEmployer1(data) {
    const response = await createEmployer(data);
    navigate("/admin-home/");
  }
  async function updateEmployer1(data) {
    try {
      const response = await updateEmployer(data); // Call the API function

      if (response.data) {
        // Success handling
        const saveButton = document.getElementById('saveButton');
        if (saveButton) {
          saveButton.innerHTML += ' ✔️';
        }
        window.scrollTo({ top: 0, behavior: 'smooth' });
      } else if (response.error) {
        // Handle API error response
        alert(
          `Failed to update the employer. Message: ${response.error.data.errorDetails.message || 'Unknown error occurred.'
          }`
        );
      } else {
        // Handle unexpected response format
        alert('Unexpected response format. Please try again.');
      }
    } catch (error) {
      // Catch unexpected errors (e.g., network issues)
      console.error('Error updating employer:', error);
      alert(
        `An unexpected error occurred while updating the employer. Error: ${error.message || 'Unknown error'
        }`
      );
    }
  }


  content = (

    <div className="w-full gap-4 pb-16">
      {employer && (

        <div className="w-1/2 bg-slate-100 rounded-b-xl mx-4 flex justify-between border-t-2 border-white shadow-xl">
          <button
            className={`w-1/3 text-center py-4 border-white rounded-bl-lg font-bold border-r-2 transition duration-300 ease-in-out ${activeTab === "profile" ? "bg-amber-400" : "bg-slate-100 hover:bg-slate-200"
              }`}
            onClick={() => setActiveTab("profile")}
          >
            Profile

          </button>
          <button
            className={`w-1/3 text-center py-4 border-white font-bold border-r-2 transition duration-300 ease-in-out ${activeTab === "hub" ? "bg-amber-400" : "bg-slate-100 hover:bg-slate-200"
              }`}
            onClick={() => setActiveTab("hub")}
          >
            Career Hub Profile
          </button>
          <button
            className={`w-1/3 text-center py-4 border-white font-bold border-r-2 transition duration-300 ease-in-out ${activeTab === "sales" ? "bg-amber-400" : "bg-slate-100 hover:bg-slate-200"
              }`}
            onClick={() => setActiveTab("sales")}
          >
            Sales & Contacts

          </button>
          <button
            className={`w-1/3 text-center py-4 border-white font-bold border-r-2 transition duration-300 ease-in-out ${activeTab === "SEO" ? "bg-amber-400" : "bg-slate-100 hover:bg-slate-200"
              }`}
            onClick={() => setActiveTab("SEO")}
          >
            SEO content

          </button>
          <button
            className={`w-1/3 text-center py-4 font-bold rounded-br-lg border-white border-r-2 transition duration-300 ease-in-out ${activeTab === "invoice" ? "bg-amber-400" : "bg-slate-100 hover:bg-slate-200"
              }`}
            onClick={() => setActiveTab("invoice")}
          >
            Invoicing
          </button>
          <button
            className={`w-1/3 text-center py-4 font-bold rounded-br-lg border-white border-r-2 transition duration-300 ease-in-out ${activeTab === "users" ? "bg-amber-400" : "bg-slate-100 hover:bg-slate-200"
              }`}
            onClick={() => setActiveTab("users")}
          >
            Users
          </button>
          <button
            className={`w-1/3 text-center py-4 font-bold rounded-br-lg border-white transition duration-300 ease-in-out ${activeTab === "jobs" ? "bg-amber-400" : "bg-slate-100 hover:bg-slate-200"
              }`}
            onClick={() => setActiveTab("jobs")}
          >
            Jobs
          </button>
        </div>
      )}


      {activeTab === "profile" && (
        <form className="flex flex-row max-w-full w-full" onSubmit={handleSubmit(onSubmit)}>

          <div className=" w-1/2 space-y-4 p-4">
            {!employer && (
              <p className="text-3xl font-bold text-black shadow-xl px-2 pb-4 mt-4 mb-6">
                Create Employer
              </p>
            )}
            <div className="flex flex-col justify-between rounded-b-xl px-4 mt-0">
              <div>
                <label className=" text-right text-sky-800 font-bold">
                  {employer?.company_name} Employer Ranking
                </label>
                <div className="text-left">
                  <div className="flex items-center justify-start">
                    <div className="form-control items-start mb-2 mr-4">
                      <label className="flex items-start justify-start label cursor-pointer">
                        <input
                          {...register("ranking")}
                          type="radio"
                          className="radio radio-warning radio-xs mr-1"
                          value="1"
                          onChange={(e) => {
                            const selectedValue = +e.target.value; // Using the unary plus operator to convert to a number
                            // Now `selectedValue` will be a number, not a string
                            // You can use it as needed
                          }}
                        />
                        <span className="label-text text-xs">1</span>
                      </label>
                    </div>
                    <div className="form-control items-start mb-2 mr-4">
                      <label className="flex items-start justify-start label cursor-pointer">
                        <input
                          {...register("ranking")}
                          type="radio"
                          className="radio radio-warning radio-xs mr-1"
                          value="2"
                        />
                        <span className="label-text text-xs">2</span>
                      </label>
                    </div>
                    <div className="form-control items-start mb-2  mr-4">
                      <label className="flex items-start justify-start label cursor-pointer">
                        <input
                          {...register("ranking")}
                          type="radio"
                          className="radio radio-warning radio-xs mr-1"
                          value="3"
                        />
                        <span className="label-text text-xs">3</span>
                      </label>
                    </div>
                    <div className="form-control items-start mb-2 mr-4">
                      <label className="flex items-start justify-start label cursor-pointer">
                        <input
                          {...register("ranking")}
                          type="radio"
                          className="radio radio-warning radio-xs mr-1"
                          value="4"
                        />
                        <span className="label-text text-xs">4</span>
                      </label>
                    </div>
                    <div className="form-control items-start mb-2 mr-4">
                      <label className="flex items-start justify-start label cursor-pointer">
                        <input
                          {...register("ranking")}
                          type="radio"
                          className="radio radio-warning radio-xs mr-1"
                          value="5"
                        />
                        <span className="label-text text-xs">5</span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-2">
              <label className="font-bold underline">
                University World Ranking
              </label>
              <div className="w-1/4">
                <InputBlock4
                  type="number"
                  field="worldRanking"
                  label="World Ranking"
                  register={register}
                  errors={errors}
                  forceClass="w-1/3"
                />
              </div>
            </div>
            <div className="flex flex-col gap-2">
              <label className="font-bold underline">
                AJ Admin Agent
              </label>
              <select className="border border-gray-400 w-1/4" id="userDropdown" {...register("AJAdminAgent")}>
                <option value="">Select an Agent</option>
                {users.map((user) => (
                  <option key={user.id} value={user.id}>
                    {user.firstName}
                  </option>
                ))}
              </select>
            </div>
            <div className="flex flex-col gap-2">
              <label className="font-bold underline">
                AJ Sales Agent
              </label>
              <select className="border border-gray-400 w-1/4" id="userDropdown" {...register("AJSalesAgent")}>
                <option value="">Select an Agent</option>
                {[{ name: 'Jarrod Kanizay', id: 153 }, { name: 'Grace', id: 161 }].map((user) => (
                  <option key={user.id} value={user.id}>
                    {user.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="flex flex-col gap-2">
              <label className="font-bold underline">
                Institution Name
              </label>
              <div className="w-[50%] text-left">
                <InputBlock4
                  type="text"
                  field="company_name"
                  label="company_name"
                  register={register}
                  errors={errors}
                  forceClass=""
                />
              </div>
            </div>
            <div className="flex flex-col gap-2">
              <label className="font-bold underline">Commonly Used Name / Acronym</label>
              <div className="w-[50%] text-left">
                <InputBlock4
                  type="text"
                  field="acronym"
                  label="Acronym"
                  register={register}
                  errors={errors}
                  forceClass=""
                />
              </div>
            </div>
            <div className="flex flex-col gap-2">
              <label className="font-bold underline">Rate My Professor Meta Title</label>
              <div className="w-[50%] text-left">
                <InputBlock4
                  type="text"
                  field="professorRatingMetaTitle"
                  label="Rate My Professor Meta Title"
                  register={register}
                  errors={errors}
                  forceClass=""
                />
              </div>
            </div>
            <div className="flex gap-4">
              <div className="flex justify-end font-bold">
                <div className="w-[5rem] h-[8rem] mr-1  ">
                  {logo ? (
                    <img
                      src={`https://academicjobs.s3.amazonaws.com/img/university-logo/${logo}`}
                      alt={defaultValues?.company_name}
                      className="w-full h-full object-contain rounded-md bg-white"
                    />
                  ) : (
                    <p className=" top-0 right-0 text-right font-bold px-2 py-1">Logo</p>
                  )}
                </div>
              </div>
              <div className="w-[80%] text-left justify-center pt-8">
                {employer ? (
                  <a className="text-gray-600 underline hover:text-blue-600" target="_blank" href={`https://www.google.com/search?tbm=isch&q=${encodeURIComponent(employer.company_name)} Logo`}>Google image link: {employer.company_name}</a>
                ) : (
                  <form
                    action="https://www.google.com/search"
                    method="get"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <input
                      type="hidden"
                      name="tbm"
                      value="isch"
                    />
                    <input
                      type="text"
                      name="q"
                      placeholder="Search Google Images"
                      className="border mr-6"
                    />
                    <button className="border hover:bg-gray-600 hover:text-white" type="submit">Search</button>
                  </form>
                )}
                <div className="w-[80%] text-left justify-center pt-4">
                  <div className="flex">
                    <InputBlock4 type="text" field="logo" label="Logo" register={register} errors={errors} forceClass="mb-2" />
                    <div className="info-icon" content="Ensure naming format is followed: Academic_Jobs_Company_Name">
                      <span className="info-text">Ensure naming format is followed: Academic_Jobs_Company_Name</span>
                    </div>
                  </div>
                  <input type="file" id="fileInput" name="avatar"  {...avatarField} onChange={onUploadAvatar} />
                </div>
              </div>
            </div>


            <div className="w-full flex flex-wrap gap-4">
              <label className="w-full text-left font-bold underline">
                Employer Type
              </label>
              <div className="w-full flex flex-wrap gap-3 text-left">
                <div className="flex gap-1">
                  <label className="text-sm text-right font-bold">
                    Unlimited
                  </label>
                  <div className="text-left">
                    <input
                      type="checkbox"
                      {...register("unlimitedYN")}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setValue("ranking", "5");
                        } else {
                          setValue("ranking", "3");
                        }
                      }}
                    />
                  </div>
                </div>
                <div className="flex gap-1">
                  <label className="text-sm text-right font-bold">
                    Region Top Uni
                  </label>
                  <div className="text-left">
                    <input
                      type="checkbox"
                      {...register("top20University")}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setValue("ranking", "5");
                          setValue("clientType", "freeListing");
                        } else {
                          setValue("ranking", "3");
                        }
                      }}
                    />
                  </div>
                </div>
                <div className="flex gap-1">
                  <label className="text-sm text-right font-bold">
                    Job Elephant Client
                  </label>
                  <div className="text-left">
                    <input
                      type="checkbox"
                      {...register("jobElephantClientYN")}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setValue("ranking", "5");
                        } else {
                          setValue("ranking", "3");
                        }
                      }}
                    />
                  </div>
                </div>
                <div className="flex gap-1">
                  <label className="text-sm text-right font-bold">
                    Sales Lead Client
                  </label>
                  <div className="text-left">
                    <input
                      type="checkbox"
                      {...register("salesLeadClientYN")}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setValue("clientType", "HeadlineOnly");
                        } else {
                          setValue("clientType", "");
                        }
                      }}
                    />
                  </div>
                </div>
                <div className="flex gap-1">
                  <label className="text-sm text-right font-bold">
                    Industry Client
                  </label>
                  <div className="text-left">
                    <input
                      type="checkbox"
                      {...register("industryClientYN")}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setValue("ranking", "5");
                        } else {
                          setValue("ranking", "3");
                        }
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="flex flex-wrap gap-1">
              <label className="w-full text-left font-bold underline">
                Job Listing Type
              </label>
              <div className="w-full text-left">
                <div className="flex flex-wrap items-start justify-start">
                  <div className="form-control items-start mb-2 mr-4">
                    <label className="flex items-center justify-start label cursor-pointer">
                      <input
                        {...register("clientType")}
                        type="radio"
                        className="radio radio-warning radio-xs mr-1"
                        value="unlimited"
                      />
                      <span className="label-text text-xs">Unlimited</span>
                    </label>
                  </div>
                  <div className="form-control items-start mb-2 mr-4">
                    <label className="flex items-center justify-start label cursor-pointer">
                      <input
                        {...register("clientType")}
                        type="radio"
                        className="radio radio-warning radio-xs mr-1"
                        value="jobPadding"
                      />
                      <span className="label-text text-xs">Job Padding (FREE)</span>
                    </label>
                  </div>
                  <div className="form-control items-start mb-2 mr-4">
                    <label className="flex items-center justify-start label cursor-pointer">
                      <input
                        {...register("clientType")}
                        type="radio"
                        className="radio radio-warning radio-xs mr-1"
                        value="paidSingleListing"
                      />
                      <span className="label-text text-xs">Paid Single Listing</span>
                    </label>
                  </div>
                  <div className="form-control items-start mb-2">
                    <label className="flex items-center justify-start label cursor-pointer">
                      <input
                        {...register("clientType")}
                        type="radio"
                        className="radio radio-warning radio-xs mr-1"
                        value="HeadlineOnly"
                      />
                      <span className="label-text text-xs">Headline Only</span>
                    </label>
                  </div>
                </div>

              </div>
            </div>
            <div className="flex gap-4">
              <label className=" text-left font-bold">News Provider</label>
              <input
                {...register("newsProvider")}
                type="checkbox"
                className="mr-1 "
              />
            </div>
            <div className="flex flex-col gap-1">
              <label className="text-red-600 font-bold underline">
                Institution Location
              </label>
              <div className=" text-left">
                <Autocomplete
                  className="border-2 rounded mt-2 text-pink-400"
                  // defaultValue="Start typing job location & allow Google to auto-complete"
                  defaultValue={employer?.location}
                  style={{ width: "100%" }}
                  ref={googleAddressRef}
                  apiKey="AIzaSyCKEfoOIPz8l_6A8BByD3b3-ncwza8TNiA"
                  {...register("location1")}
                  onPlaceSelected={(place) => {
                    console.log("Selected Place:", place);
                    // Extracting address components
                    const addressComponents = place.address_components || [];
                    // Initialize variables for city, state, and country
                    let city = "";
                    let state = "";
                    let country = "";
                    let postalCode = "";
                    // Iterate through address components to find relevant information
                    addressComponents.forEach((component) => {
                      const types = component.types || [];
                      if (types.includes("locality")) {
                        city = component.long_name;
                      } else if (types.includes("administrative_area_level_1")) {
                        state = component.long_name;
                      } else if (types.includes("country")) {
                        country = component.long_name;
                      } else if (types.includes("postal_code")) {
                        postalCode = component.long_name;
                      }
                    });
                    console.log("City:", city);
                    console.log("State:", state);
                    console.log("Country:", country);
                    setValue("location", place.formatted_address);
                    setValue("city", city);
                    setValue("state", state);
                    setValue("country", country);
                    setValue("Region", country);
                    setValue("postalCode", postalCode);
                  }}
                  // onPlaceSelected={(selected, a, c) => {
                  //   console.log(selected,a,c);
                  //   setValue("location", selected)
                  // }}
                  options={{
                    types: ["geocode", "establishment"],
                    //componentRestrictions: { country: "Australia" },
                  }}
                  //onPlaceSelected={(place) =>
                  //formik.setFieldValue("countryAnother", place.formatted_address)
                  //}
                  onChange={(e) => setValue("location", e.target.value)}
                //onChange={e => console.log(e)}
                />
              </div>
            </div>
            {isAddMode && (
              <div className="space-y-6">
                <div className="space-y-4 p-4 border border-gray-300 rounded-lg">
                  <p className="font-bold underline text-lg">Main Contact</p>

                  <div className="flex gap-4">
                    <label className="w-[30%] text-right font-bold">First Name</label>
                    <div className="w-[50%] text-left">
                      <InputBlock4 type="text" field="firstname1" label="firstname1" register={register} errors={errors} />
                    </div>
                  </div>

                  <div className="flex gap-4">
                    <label className="w-[30%] text-right font-bold">Last Name</label>
                    <div className="w-[50%] text-left">
                      <InputBlock4 type="text" field="lastname1" label="lastname1" register={register} errors={errors} />
                    </div>
                  </div>

                  <div className="flex gap-4">
                    <label className="w-[30%] text-right font-bold">Job Title</label>
                    <div className="w-[50%] text-left">
                      <InputBlock4 type="text" field="jobtitle1" label="jobtitle1" register={register} errors={errors} />
                    </div>
                  </div>

                  <div className="flex gap-4">
                    <label className="w-[30%] text-right font-bold">Email</label>
                    <div className="w-[50%] text-left">
                      <InputBlock4 type="text" field="email1" label="email1" register={register} errors={errors} />
                    </div>
                  </div>

                  <div className="flex gap-4">
                    <label className="w-[30%] text-right font-bold">Phone Number</label>
                    <div className="w-[50%] text-left">
                      <InputBlock4 type="text" field="phone1" label="phone1" register={register} errors={errors} />
                    </div>
                  </div>
                </div>

                <div className="space-y-4 p-4 border border-gray-300 rounded-lg">
                  <p className="font-bold underline text-lg">Secondary Contact</p>

                  <div className="flex gap-4">
                    <label className="w-[30%] text-right font-bold">First Name</label>
                    <div className="w-[50%] text-left">
                      <InputBlock4 type="text" field="firstname2" label="firstname2" register={register} errors={errors} />
                    </div>
                  </div>

                  <div className="flex gap-4">
                    <label className="w-[30%] text-right font-bold">Last Name</label>
                    <div className="w-[50%] text-left">
                      <InputBlock4 type="text" field="lastname2" label="lastname2" register={register} errors={errors} />
                    </div>
                  </div>

                  <div className="flex gap-4">
                    <label className="w-[30%] text-right font-bold">Job Title</label>
                    <div className="w-[50%] text-left">
                      <InputBlock4 type="text" field="jobtitle2" label="jobtitle2" register={register} errors={errors} />
                    </div>
                  </div>

                  <div className="flex gap-4">
                    <label className="w-[30%] text-right font-bold">Email</label>
                    <div className="w-[50%] text-left">
                      <InputBlock4 type="text" field="email2" label="email2" register={register} errors={errors} />
                    </div>
                  </div>

                  <div className="flex gap-4">
                    <label className="w-[30%] text-right font-bold">Phone Number</label>
                    <div className="w-[50%] text-left">
                      <InputBlock4 type="text" field="phone2" label="phone2" register={register} errors={errors} />
                    </div>
                  </div>
                </div>
              </div>
            )}


            <div className="flex flex-col gap-1">
              <label className="font-bold underline">
                Staff Directory URL
              </label>
              <div className="">
                <InputBlock4
                  type="text"
                  field="contactPageURL"
                  label="contactPageURL"
                  register={register}
                  errors={errors}
                  forceClass=""
                />
              </div>
            </div>
            {/* <label className="mt-8 text-lg font-bold underline">Other Details</label> */}
            <div className="flex flex-col gap-1">
              <label className="text-red-600 font-bold underline">Website</label>
              <div className="text-left">
                <InputBlock4
                  type="text"
                  field="website"
                  label="website"
                  register={register}
                  errors={errors}
                  forceClass=""
                />
              </div>
            </div>
            <div className="flex flex-col gap-1">
              <label className="font-bold underline">Email Domain</label>
              <div className="">
                <InputBlock4
                  type="text"
                  field="emailDomain"
                  label="emailDomain"
                  register={register}
                  errors={errors}
                  forceClass=""
                />
              </div>
            </div>
            <div className="flex flex-col gap-1">
              <label className="underline text-red-600 font-bold">
                Employer Job Page URL
              </label>
              <div className="">
                <InputBlock4
                  type="text"
                  field="employerPageURL"
                  label="employerPageURL"
                  register={register}
                  errors={errors}
                  forceClass=""
                />
              </div>
            </div>

            <div className="flex flex-col gap-1">
              <label className="underline text-red-600 font-bold">
                Quantity of Jobs on Employer website (approx.)
              </label>
              <div className="">
                <InputBlock4
                  type="text"
                  field="approxJobQtyWebsite"
                  label="approxJobQtyWebsite"
                  register={register}
                  errors={errors}
                  forceClass=""
                />
              </div>
            </div>
            <div className="flex flex-col gap-1">
              <label className="underline font-bold">Login Email</label>
              <div className="">
                <InputBlock4
                  type="text"
                  field="email"
                  label="email"
                  register={register}
                  errors={errors}
                  forceClass=""
                />
              </div>
            </div>
            <div className="flex flex-col gap-1">
              <label className="underline font-bold">Login Password</label>
              <div className="">
                <InputBlock4
                  type="text"
                  field="password"
                  label="password"
                  register={register}
                  errors={errors}
                  forceClass=""
                />
              </div>
            </div>
          </div>
          <div className="w-1/2 space-y-4 p-4 relative"> {/* Add `relative` */}
            <div className="sticky top-0 z-10 col-span-2 flex justify-end">
              <button
                className="btn btn-success mr-8 mt-4 shadow-lg"
                id="saveButton"
                onClick={async (e) => {
                  // Your logic here
                }}
              >
                Save Changes
              </button>
            </div>
            <div className="text-left">
              <div className="flex flex-col">
                <label className="text-lg font-bold underline">SEO Keywords</label>
                <label className="font-bold mt-4">Primary Keywords (ensure casing is correct)</label>
              </div>
              <InputBlock4
                type="text"
                field="primaryKeywords"
                label="Primary Keywords"
                register={register}
                errors={errors}
                forceClass=""
              />
            </div>
            <div>
              <label className="font-bold">Secondary Keywords (ensure casing is correct)</label>
              <div className="w-[50%] text-left">
                <InputBlock4
                  type="text"
                  field="secondaryKeywords"
                  label="Secondary Keywords"
                  register={register}
                  errors={errors}
                  forceClass=""
                />
              </div>
            </div>

            <div>
              <label className="font-bold">Keywords</label>
              <ReactQuill
                modules={modules} theme="snow"
                value={employer?.seoKeywords}
                className=""
                onChange={(value) => {
                  setValue("seoKeywords", value);
                }}
                style={{ minHeight: '20px' }}

              />
            </div>

            <div className="mt-8 text-lg font-bold underline">
              <label>Employer Profile Description (Ensure H1 is added in seperate field below)</label>
            </div>
            <div>
              <label className="font-bold">Podcast URL</label>
              <div className="w-[50%] text-left mb-4">
                <InputBlock4
                  type="text"
                  field="podcastURL"
                  label="Podcast URL"
                  register={register}
                  errors={errors}
                  forceClass=""
                />
              </div>
            </div>

            <div>
              <label className="font-bold">Profile H1</label>
              <div className="w-[50%] text-left mb-4">
                <InputBlock4
                  type="text"
                  field="h1Profile"
                  label="Podcast URL"
                  register={register}
                  errors={errors}
                  forceClass=""
                />
              </div>
            </div>

            <div>
              <label className="font-bold underline">Short Employer Description</label>
              <p><strong>Suggested Guidelines:</strong> A short summary is vital for the A-Z recruiter listing page and job description details pages. This needs to be impactful within the 255-character limit. It should highlight the key benefits and points from the full job description.</p>

              <ReactQuill
                modules={modules}
                formats={formats}
                value={employer?.shortDescription}
                onChange={(value) => setValue("shortDescription", value)}
                className="border border-gray-300 rounded-lg mt-6"
                style={{ minHeight: '50px' }}

              />
            </div>

            <div>
              <label className="font-bold underline">How the organisation shines in Higher Ed (General Description)</label>
              <p><strong>Suggested Guidelines:</strong> The profile should offer a detailed insight into the organisation, including its research capabilities, successes, founding year, and future plans. Highlighting awards, new research areas, and thought leadership strengthens the employer's appeal. Visual elements are essential.</p>

              <ReactQuill
                modules={modules}
                formats={formats}
                value={employer?.company_description}
                onChange={(value) => setValue("company_description", value)}
                className="border border-gray-300 rounded-lg mt-6"
                style={{ minHeight: '50px' }}

              />
            </div>

            <div>
              <label className="font-bold underline">Working Environment & Culture – what it is like for staff on campus  </label>
              <p>
                <strong>Suggested Guidelines:</strong> The profile should offer information about what it's like to work at the organisation. Candidates are interested in the people they will be working with and the working environment. We also suggest highlighting if your current team is tenured and exceptional, or if the premises and location are appealing.
              </p>
              <ReactQuill
                modules={modules}
                formats={formats}
                value={employer?.workCulture}
                onChange={(value) => setValue("workCulture", value)}
                className="border border-gray-300 rounded-lg mt-6"
                style={{ minHeight: '50px' }}
              />
            </div>

            <div>
              <label className="font-bold underline">Employee Benefits Beyond Salary </label>
              <p>
                <strong>Suggested Guidelines:</strong> Candidates are interested in benefits beyond salary, such as training and development opportunities, volunteer days, and continuous career development. These perks should be clearly articulated in the profile.
              </p>
              <ReactQuill
                modules={modules}
                formats={formats}
                value={employer?.employeeBenefits}
                onChange={(value) => setValue("employeeBenefits", value)}
                className="border border-gray-300 rounded-lg mt-6"
                style={{ minHeight: "150px" }}

              />
            </div>

            <div>
              <label className="font-bold underline">How to apply for job openings, with all appropriate contact details </label>
              <p>
                <strong>Suggested Guidelines:</strong> Provide complete contact and address information, including a link to the organisation's website. This allows candidates to research the working environment further and show the employer is being open and transparent.
              </p>
              <ReactQuill
                modules={modules}
                formats={formats}
                value={employer?.howToApply}
                onChange={(value) => setValue("howToApply", value)}
                className="border border-gray-300 rounded-lg mt-6"
                style={{ minHeight: "150px" }}

              />
            </div>
          </div>
        </form>)}

      {activeTab === "SEO" && (
        <form className="flex w-full flex-col space-y-4" onSubmit={handleSubmit(onSubmit)}>
          <div className="sticky top-0 z-10 col-span-2 flex justify-end">
            <button
              className="btn btn-success mr-8 mt-4 shadow-lg"
              id="saveButton"
              onClick={async (e) => {
                // Your logic here
              }}
            >
              Save Changes
            </button>
          </div>
          <div>

            <label className="mt-6 text-lg font-bold">Notable Alumni</label>
            <div>
              <a className="text-blue-600 underline hover:text-blue-600" target="_blank" href={`https://www.google.com/search?q=${encodeURIComponent(employer.company_name)} notable alumni wiki`}>Notable Alumni Wiki link</a>
            </div>
            <ReactQuill
              modules={modules} theme="snow"
              value={employer?.notableAlumni}
              onChange={(value) => {
                setValue("notableAlumni", value);
              }}
              className="border border-gray-300 rounded-lg"
              style={{ minHeight: "150px" }} />
          </div>

          <div>
            <label className="mt-6 text-lg font-bold">Salaries</label>
            <ReactQuill
              modules={modules} theme="snow"
              value={employer?.salaries}
              onChange={(value) => {
                setValue("salaries", value);
              }}
              className="border border-gray-300 rounded-lg"
              style={{ minHeight: "150px" }}

            /></div>
          <div>

            <label className="mt-6 text-lg font-bold">Weather</label>
            <ReactQuill
              modules={modules} theme="snow"
              value={employer?.weather}
              onChange={(value) => {
                setValue("weather", value);
              }}
              className="border border-gray-300 rounded-lg"
              style={{ minHeight: "150px" }}

            />
          </div>

          <div>
            <label className="mt-6 text-lg font-bold">Campus Map</label>

            <ReactQuill
              modules={modules} theme="snow"
              value={employer?.campusMap}
              onChange={(value) => {
                setValue("campusMap", value);
              }}
              className="border border-gray-300 rounded-lg"
              style={{ minHeight: "150px" }} />
          </div>
          <div>
            <label className="mt-6 text-lg font-bold">Department / Faculties</label>
            <ReactQuill
              modules={modules} theme="snow"
              value={employer?.faculties}
              onChange={(value) => {
                setValue("faculties", value);
              }}
              className="border border-gray-300 rounded-lg"
              style={{ minHeight: "150px" }}
            />

          </div>
          <div>
            <label className="mt-6 text-lg font-bold">pHD program</label>
            <ReactQuill
              modules={modules} theme="snow"
              value={employer?.phdProgram}
              onChange={(value) => {
                setValue("phdProgram", value);
              }}
              className="border border-gray-300 rounded-lg"
              style={{ minHeight: "150px" }}
            />
          </div>
          <div></div>


          {/* <label className="mt-6 text-lg font-bold">Resume Templates</label>
          <ReactQuill
            modules={modules} theme="snow"
            value={employer?.resumeTemplates}
            className=""
            onChange={(value) => {
              setValue("resumeTemplates", value);
            }}
            style={{ minHeight: '50px' }}

          /> */}


          <div>
            <label className="mt-6 text-lg font-bold">FAQs</label>
            <ReactQuill
              modules={modules} theme="snow"
              value={employer?.faqs}
              onChange={(value) => {
                setValue("faqs", value);
              }}
              className="border border-gray-300 rounded-lg"
              style={{ minHeight: "150px" }}
            />

          </div>

          <div>
            <label className="mt-6 text-lg font-bold">Academic Calendar</label>
            <ReactQuill
              modules={modules} theme="snow"
              value={employer?.academicCalendar}
              onChange={(value) => {
                setValue("academicCalendar", value);
              }}
              className="border border-gray-300 rounded-lg"
              style={{ minHeight: "150px" }} />
          </div>
        </form>
      )}


      {activeTab === "sales" && employer?.id >= 0 && (
        <div className="sales-tab flex flex-row">
          <div className="w-1/2">
            <SalesNotes id={employer?.id} />
          </div>
          <div className="w-1/2">
            <OtherContacts id={employer?.id} employer={employer} />
          </div>
        </div>
      )}

      {activeTab === "hub" && employer?.id >= 0 && (
        <div className="hub-tab">
          <CareerHubProfile employer={employer} handleSubmit={onSubmit} />
        </div>
      )}

      {activeTab === "jobs" && employer?.id >= 0 && (
        <div className="jobs-tab">
          <SearchResults2
            q={{ employer_id: employer?.id || 0, expired: "0" }}
          />
        </div>
      )}


      {activeTab === "invoice" && employer?.id >= 0 && (
        <div className="invoice-tab">
          <CreateInvoice employer={employer} />
        </div>
      )}
      {activeTab === "users" && employer?.id >= 0 && (
        <div className="user-tab">
          <AddEditUsers id={employer?.id} userType={"employer"} employer_name={employer?.company_name} employer_details={employer} />
        </div>
      )}
    </div>

  );

  return <div className="overflow-y w-full">{content}</div>;
};

export default EmployerAddEdit;
