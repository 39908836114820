import React, { useState } from 'react';
import {
  useGetOtherContactsQuery,
} from "../store/apiSlice";
import AddOtherContacts from './AddOtherContacts';
import OtherContactsRowEdit from './OtherContactsRowEdit';
import OtherContactsRowEditQuickCheck from './OtherContactsRowEditQuickCheck';

const OtherContacts = ({ id, employer }) => {
  const { data, isLoading, isSuccess, isError } = useGetOtherContactsQuery({ id });
  const [isExpanded, setIsExpanded] = useState(true);
  const [selectedContact, setSelectedContact] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [filter, setFilter] = useState({
    contactTypes: [],
    contactTypeNotAssigned: false, // New filter for contactType being null
    hr: false,
    recruitment: false,
    departmentLead: false,
    notAssigned: false,
  });

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const handleEditClick = (contact) => {
    setSelectedContact(contact);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setSelectedContact(null);
    setIsModalOpen(false);
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setFilter(prevFilter => ({
      ...prevFilter,
      [name]: checked,
    }));
  };

  const handleContactTypeChange = (contactType) => {
    setFilter(prevFilter => {
      const contactTypes = prevFilter.contactTypes.includes(contactType)
        ? prevFilter.contactTypes.filter(type => type !== contactType)
        : [...prevFilter.contactTypes, contactType];
      return { ...prevFilter, contactTypes };
    });
  };

  const copyToClipboard = () => {
    const csvData = filteredData.map(contact => (
      `"${contact.firstName}","${contact.lastName}","${contact.title}","${contact.email}","${contact.phone}"`
    ));

    const csvContent = [
      '"First Name","Last Name","Title","Email","Phone"',
      ...csvData
    ].join('\n');

    navigator.clipboard.writeText(csvContent).then(() => {
      alert('Data copied to clipboard as CSV!');
    }).catch(err => {
      console.error('Failed to copy text: ', err);
    });
  };

  const filteredData = data?.filter(contact => {
    const matchesContactType = filter.contactTypes.length > 0
      ? filter.contactTypes.includes(contact.contactType)
      : true;

    const matchesContactTypeNotAssigned = filter.contactTypeNotAssigned
      ? contact.contactType === null
      : true;

    const matchesHr = filter.hr ? contact.hrYN === 1 : true;
    const matchesRecruiter = filter.recruitment ? contact.recruiterYN === 1 : true;
    const matchesDepartmentLead = filter.departmentLead ? contact.departmentLeadYN === 1 : true;
    const matchesNotAssigned = filter.notAssigned ? (contact.hrYN === 0 && contact.recruiterYN === 0 && contact.departmentLeadYN === 0) : true;

    // If no other filters are selected, default to true
    const anyFilterSelected = filter.hr || filter.recruitment || filter.departmentLead || filter.notAssigned || filter.contactTypeNotAssigned;
    const matchesOtherFilters = anyFilterSelected
      ? (matchesHr && matchesRecruiter && matchesDepartmentLead && matchesNotAssigned)
      : true;

    // Show contacts that match contactType or contactTypeNotAssigned and other filters
    return matchesContactType && matchesContactTypeNotAssigned && matchesOtherFilters;
  }) || [];


  if (isLoading) {
    return <p>Loading...</p>;
  }
  if (isError) {
    return <p>No contacts found...</p>;
  }

  return (
    <div
      className={`overflow-y-auto w-full mb-8 transition-all duration-300`}
    >
      {/* <button
        onClick={toggleExpand}
        className="bg-blue-500 text-white px-4 py-2 mb-4 rounded"
      >
        {isExpanded ? "Collapse" : "Expand"}
      </button> */}

      <div className='bg-slate-50 p-6 rounded-xl mb-8 text-gray-600 font-bold shadow-black'>
        <h2 className='font-bold underline pt-2 mb-2'>Add New Contact</h2>
        <div>        
          <a className="text-blue-600 underline hover:text-blue-600" target="_blank" href={`https://www.google.com/search?q=talent acquisition manager ${encodeURIComponent(employer.company_name)}`}>Talent Acquisition Manager link</a>
        </div>
        <div>        
          <a className="text-blue-600 underline hover:text-blue-600" target="_blank" href={`https://www.google.com/search?q=hr recruitment manager ${encodeURIComponent(employer.company_name)}`}>HR Recruitment Manager link</a>
        </div>
        <AddOtherContacts id={id} />
      </div>

      <div className="flex justify-between items-center p-4">
        <div className="flex flex-col gap-2">
          <span className='font-bold'>Filter by Contact Type</span>
          <div className="flex gap-4">
            <label >
              <input
                type="checkbox"
                onChange={() => handleContactTypeChange('MAIN')}
                checked={filter.contactTypes.includes('MAIN')}
                className='mr-1'

              />
              Main
            </label>
            <label>
              <input
                type="checkbox"
                onChange={() => handleContactTypeChange('SECONDARY')}
                checked={filter.contactTypes.includes('SECONDARY')}
                className='mr-1'
              />
              Secondary
            </label>
            <label>
              <input
                type="checkbox"
                onChange={() => handleContactTypeChange('OTHER')}
                checked={filter.contactTypes.includes('OTHER')}
                className='mr-1'
              />
              Other
            </label>
            <label>
              <input
                type="checkbox"
                name="contactTypeNotAssigned"
                checked={filter.contactTypeNotAssigned}
                onChange={handleCheckboxChange}
                className='mr-1'
              />
              Not Assigned
            </label>
          </div>
        </div>

        <div className="flex flex-col gap-2">
          <span className='font-bold'>Other Filters</span>
          <div className="flex gap-4">
            <label>
              <input
                type="checkbox"
                name="hr"
                checked={filter.hr}
                onChange={handleCheckboxChange}
                className='mr-1'
              />
              HR
            </label>
            <label>
              <input
                type="checkbox"
                name="recruitment"
                checked={filter.recruitment}
                onChange={handleCheckboxChange}
                className='mr-1'
              />
              Recruitment
            </label>
            <label>
              <input
                type="checkbox"
                name="departmentLead"
                checked={filter.departmentLead}
                onChange={handleCheckboxChange}
                className='mr-1'

              />
              Department Lead
            </label>
            <label>
              <input
                type="checkbox"
                name="notAssigned"
                checked={filter.notAssigned}
                onChange={handleCheckboxChange}
                className='mr-1'
              />
              Not Assigned
            </label>
          </div>
        </div>

        <button className="btn btn-xs bg-green-500 text-white" onClick={copyToClipboard}>
          Copy to Clipboard
        </button>
      </div>

      {isSuccess && filteredData.length > 0 ? (
        <div>
          <ul className="list-none p-4">
            {filteredData.map((contact) => (
              <li key={contact.id} className="flex justify-between items-center p-2 border-b">
                <div>
                  <p>{contact.firstName} {contact.lastName} ({contact.title})</p>
                  <p className="text-sm text-gray-500">{contact.email}</p>
                  <p className="text-sm text-gray-500">{contact.phone}</p>
                </div>
                <OtherContactsRowEditQuickCheck contact={contact} />
                <button
                  className="btn btn-xs"
                  onClick={() => handleEditClick(contact)}
                >
                  Edit
                </button>
              </li>
            ))}
          </ul>
        </div>
      ) : (
        <p>No contacts available to display.</p>
      )}

      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <div className="fixed inset-0 bg-black opacity-50" onClick={handleCloseModal}></div>
          <div className="bg-white p-6 rounded-lg shadow-lg z-10">
            <OtherContactsRowEdit contact={selectedContact} onClose={handleCloseModal} />
          </div>
        </div>
      )}
    </div>
  );
};

export default OtherContacts;
